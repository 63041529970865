<script setup lang="ts">
import type { ButtonProps } from 'naive-ui'
import { format } from 'date-fns'
import type { Task } from '~/types/task.type'

const props = defineProps({
  taskId: {
    type: Number as PropType<Task['id']>,
    required: true,
  },
  buttonProps: {
    type: Object as PropType<ButtonProps>,
    default: () => ({}),
  },
})

const dialog = useDialog()
const timeTracker = useTimeTracker()
const { isRunning, activeTimeEntry } = storeToRefs(timeTracker)

const timeTrackerLoading = ref(false)

const isRunningForThisTask = computed(() => {
  return isRunning.value && activeTimeEntry.value?.task.id === props.taskId
})

const icon = computed(() => {
  return isRunningForThisTask.value ? 'i-ic-outline-stop-circle' : 'i-ic-outline-play-circle'
})

const tooltip = computed(() => {
  if (isRunningForThisTask.value)
    return 'Zeiteintrag abschließen'

  else if (isRunning.value)
    return 'Tracker stoppen und für diese Aufgabe starten'

  else
    return 'Tracker für diese Aufgabe starten'
})

function openTimeTrackerDialog() {
  dialog.create({
    title: 'Wirklich einen neuen Zeiteintrag starten?',
    type: 'warning',
    positiveText: 'Zeiteintrag beginnen',
    negativeText: 'Abbrechen',
    content: () => h('div', [
      h('div', 'Es läuft bereits ein Zeiteintrag für die Aufgabe '),
      h('div', { class: 'font-semibold my-2' }, activeTimeEntry.value?.task.name),
      h('div', { class: 'my-2' }, [
        h('span', 'seit dem '),
        h('span', { class: 'font-semibold' }, format(activeTimeEntry.value?.startAt || Date.now(), 'dd.MM.yy \'um\' HH:mm \'Uhr\'')),
      ]),
      h('div', 'Soll der neue Zeiteintrag wirklich begonnen werden?'),
    ]),
    onPositiveClick: async () => {
      timeTrackerLoading.value = true
      await timeTracker.stop()
      await timeTracker.start(props.taskId)
      timeTrackerLoading.value = false
    },
  })
}

async function onClick() {
  if (isRunningForThisTask.value) {
    timeTrackerLoading.value = true
    await timeTracker.stop()
    timeTrackerLoading.value = false
  }
  else if (isRunning.value) {
    openTimeTrackerDialog()
  }
  else {
    timeTrackerLoading.value = true
    await timeTracker.start(props.taskId)
    timeTrackerLoading.value = false
  }
}
</script>

<template>
  <NTooltip>
    <template #trigger>
      <NButton v-bind="buttonProps" :loading="timeTrackerLoading" @click="onClick">
        <template #icon>
          <Icon :name="icon" />
        </template>
      </NButton>
    </template>
    {{ tooltip }}
  </NTooltip>
</template>
